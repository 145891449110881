import styles from "./Spinner.module.css"
import { ReactComponent as SpinnerIcon } from "../../assets/icons/icon-spinner.svg"

export const Spinner = () => {
  return (
    <div className={styles.spinner}>
      <SpinnerIcon width="100%" height="100%" />
    </div>
  )
}
