import React from "react"
import styles from "./Section.module.css"

export const Section = ({
  title,
  description,
  children,
}: {
  title?: string
  description?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        {title && <h1>{title}</h1>}
        {description}
      </div>
      {children}
    </div>
  )
}
