import { useMemo } from "react"
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Dot,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"
import { Card } from "../card/Card"
import chartStyles from "./Chart.module.css"

export interface ChartData {
  name: string
  value: number
  errorAmount: number
}

interface ChartDataWithMinMax extends Omit<ChartData, "errorAmount"> {
  minMax: [number, number]
}

interface ChartProps {
  name: string
  valueLabel: string
  valueRange?: [number, number]
  yLabels?: number[]
  data: ChartData[]
}

const convertToMinMaxData = (data: ChartData[]): ChartDataWithMinMax[] =>
  data.map((d) => {
    const { name, value, errorAmount } = d
    return {
      name,
      value,
      minMax: [Math.max(0, value - errorAmount), value + errorAmount],
    }
  })

export default function Chart({
  data,
  name,
  valueLabel,
  valueRange = [0, 10],
  yLabels = [0, 5, 10],
}: ChartProps) {
  const dataWithMinMax = useMemo(() => convertToMinMaxData(data), [data])
  const rangeLength = valueRange[1] - valueRange[0] + 1
  const minRange = valueRange[0]
  const ticks = [...Array(rangeLength)].map((_, i) => i + minRange)

  return (
    <Card>
      <h3 className={chartStyles.chartTitle}>{name}</h3>
      {/* make rechart responsive with 99% width https://github.com/recharts/recharts/issues/172 */}
      <ResponsiveContainer width={"99%"} height={300}>
        <ComposedChart data={dataWithMinMax}>
          <defs>
            <linearGradient id="colorLine" x1="1" y1="0" x2="0" y2="0">
              <stop offset="40%" stopColor="#229426" stopOpacity={1.0} />
              <stop offset="90%" stopColor="#993f43" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="2" />
          <XAxis dataKey="name" interval="preserveStartEnd" />
          <Dot r={5} stroke="#CF6468" cy={1} cx={1} />
          <YAxis
            domain={valueRange}
            type="number"
            ticks={ticks}
            interval={0}
            width={20}
            tickFormatter={(value) => (yLabels.includes(Number(value)) ? value : "")}
            tickLine={false}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            name={valueLabel}
            stroke="url(#colorLine)"
            strokeWidth={3.2}
            dot={false}
          />
          <Area
            type={"monotone"}
            dataKey="minMax"
            fill="url(#colorLine)"
            opacity={0.3}
            stroke="transparent"
            legendType={"none"}
            tooltipType={"none"}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className={chartStyles.chartText}>
        {
          "The Aivo members whose starting recovery profile was most similar with yours have experienced this pain recovery trend. Study this report to find out more and join the Aivo program to receive your own guided recovery program."
        }
      </div>
    </Card>
  )
}
