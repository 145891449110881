import { useState } from "react"
import PageLayout from "../../components/PageLayout/PageLayout"
import Profile from "../../components/Profile/Profile"

export const ProfilePage = ({
  joinButtonParams = {},
  setParams = () => {},
}: {
  joinButtonParams?: Record<string, string>
  setParams?: (params: Record<string, string>) => void
}) => {
  const [hasError, setHasError] = useState(false)
  return (
    <PageLayout footerLogo={!hasError}>
      <Profile
        params={joinButtonParams}
        setParams={setParams}
        joinButtonParams={joinButtonParams}
        flagError={setHasError}
      />
    </PageLayout>
  )
}
