import { buildTrackingUrl } from "../Survey/Survey"
import { Button } from "./Button"
import { Waypoint } from "react-waypoint"

export const goToLandingPage = (path: string = "", params?: Record<string, string>) => {
  const url = buildTrackingUrl(path, params)
  window.location.href = url.href
}

export const JoinAivoButton = ({
  onButtonVisible,
  onButtonHidden,
  buttonId,
  params = {},
}: {
  onButtonVisible?: () => void
  onButtonHidden?: () => void
  buttonId: string
  params?: Record<string, string>
}) => {
  const { aivoPath, ...rest } = params
  return (
    <Waypoint onEnter={onButtonVisible} onLeave={onButtonHidden}>
      <Button onClick={() => goToLandingPage(aivoPath, rest)} id={buttonId}>
        Show me how Aivo can help
      </Button>
    </Waypoint>
  )
}
