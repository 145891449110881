import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { AivoTrait, getWebProfile } from "../../data/profile"
import { JoinAivoButton } from "../button/JoinAivoButton"
import { CardContainer } from "../card/CardContainer"
import Chart, { ChartData } from "../Chart/Chart"
import { Divider } from "../Divider/Divider"
import { IconCard } from "../IconCard/IconCard"
import { Section } from "../Section/Section"
import { Spinner } from "../Spinner/Spinner"
import { ThemeCard, ThemeType } from "../ThemeCard/ThemeCard"
import { TraitCard } from "../TraitCard/TraitCard"
import styles from "./Profile.module.css"

function ProfileDetail({
  traits,
  themes,
  painForecast,
  joinButtonParams,
}: {
  traits?: AivoTrait[]
  themes?: ThemeType[]
  painForecast?: ChartData[]
  joinButtonParams: Record<string, string>
}) {
  const joinAivoButtonId = "join-aivo-button"

  return (
    <>
      {painForecast && (
        <Chart data={painForecast} name="Forecast" valueLabel="PAIN LEVEL" valueRange={[0, 10]} />
      )}
      <CardContainer
        title="Your traits"
        description="Your experience of pain is shaped by the following traits."
      >
        {traits?.map((trait) => (
          <TraitCard key={trait.title} trait={trait} />
        ))}
      </CardContainer>
      <CardContainer
        title="Program personalization"
        description="The Aivo Program is unique to every member. Based on your profile the focus of your recovery path focuses on these topics"
      >
        {themes?.map((theme) => (
          <ThemeCard key={theme} theme={theme} />
        ))}
      </CardContainer>
      <Divider />
      <CardContainer
        title="Get started today"
        description="Join Aivo to manage and reverse your chronic pain. Your program benefits include:"
      >
        <IconCard
          icon="profileFemale"
          title="Assessment"
          body="The report above is just the first peek into the full assessment, which will refine the understanding of your pain. You will have the opportunity to discuss your results with pain experts one-on-one."
        />
        <IconCard
          icon="device"
          title="Personalized Program"
          body="Your personalized pain relief program is delivered to your over the Aivo app and is continously optimized from a carefully curated selection of multimodal treatments relevant to your pain relief."
        />
        <IconCard
          icon="group"
          title="Integrated Care Team"
          body="Your dedicated health coach will work with you to guide and support you towards sustainable pain relief with an integrated care team of pain experts and data scientists."
        />
      </CardContainer>
      <Section
        description={
          <p>
            Check your personal offer for the Aivo Program and take the next step toward relief.
          </p>
        }
      >
        <JoinAivoButton params={joinButtonParams} buttonId={joinAivoButtonId} />
      </Section>
    </>
  )
}

function Profile({
  params,
  setParams,
  joinButtonParams,
  flagError = () => {},
}: {
  params: Record<string, string>
  setParams: (params: Record<string, string>) => void
  joinButtonParams: Record<string, string>
  flagError?: (hasError: boolean) => void
}) {
  const [traits, setTraits] = useState<AivoTrait[]>()
  const [themes, setThemes] = useState<ThemeType[]>()
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState<string>()
  const emailRef = useRef<string>()
  const [error, setError] = useState<string | undefined>(undefined)

  let { id } = useParams()

  const [painForecast, setPainForecast] = useState<ChartData[]>()
  useEffect(() => {
    if (id) {
      setLoading(true)
      getWebProfile(id)
        .then(({ themes, traits, painForecast, email }) => {
          setTraits(traits)
          setThemes(themes.slice(0, 3))
          setPainForecast(painForecast)
          setEmail(email)
          setLoading(false)
        })
        .catch((e) => {
          setError(
            "There’s rush hour at our data science. Please retry this a bit later. You have an email to this page in your inbox.",
          )
          flagError(true)
          setLoading(false)
        })
    }
  }, [id, flagError])

  useEffect(() => {
    if (email && emailRef.current !== email) {
      emailRef.current = email
      setParams({ ...params, email })
    }
  }, [email, setParams, params])

  return (
    <>
      <Section
        title={"Your Recovery Profile"}
        description={
          !loading &&
          !error && (
            <>
              <p>
                Every person is unique and so are the aspects that affect your experience of pain.
              </p>
              <p>
                This profile is the starting point on your path to recovery. During the Aivo Program
                it will be continuously optimized as we find new opportunities for relief together.
              </p>
            </>
          )
        }
      />
      <div className={loading ? styles.spinnerShow : styles.spinnerHide}>
        <Spinner />
      </div>
      <div className={!loading ? styles.profileShow : styles.profileHide}>
        {traits && (
          <ProfileDetail
            traits={traits}
            themes={themes}
            painForecast={painForecast}
            joinButtonParams={joinButtonParams}
          />
        )}
        {error && <IconCard icon="gears" title="Oops" body={error} />}
      </div>
    </>
  )
}

export default Profile
