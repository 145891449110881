import React, { forwardRef } from "react"
import styles from "./Button.module.css"

interface ButtonProps {
  children: React.ReactNode
  onClick?: () => void
  id?: string
}
export const Button = forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
  ({ children, onClick, id }, ref) => {
    return (
      <button className={styles.button} onClick={onClick} id={id} ref={ref}>
        {children}
      </button>
    )
  },
)
