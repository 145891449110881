import React from "react"
import { Section } from "../Section/Section"
import styles from "./Card.module.css"

export const CardContainer = ({
  title,
  description,
  children,
}: {
  title?: string
  description?: string
  children?: React.ReactNode
}) => {
  return (
    <Section title={title} description={description}>
      <div className={styles.cardContainer}>{children}</div>
    </Section>
  )
}
