import { ReactComponent as ChestIcon } from "../../assets/icons/icon-chest.svg"
import { ReactComponent as MeditationIcon } from "../../assets/icons/icon-content-meditation.svg"
import { ReactComponent as DeviceIcon } from "../../assets/icons/icon-device-phone.svg"
import { ReactComponent as GearsIcon } from "../../assets/icons/icon-gears.svg"
import { ReactComponent as GiveLoveIcon } from "../../assets/icons/icon-giveLove.svg"
import { ReactComponent as GroupIcon } from "../../assets/icons/icon-group.svg"
import { ReactComponent as HighFiveIcon } from "../../assets/icons/icon-highFive.svg"
import { ReactComponent as ProfileFemaleIcon } from "../../assets/icons/icon-profile-female.svg"
import { ReactComponent as PulseIcon } from "../../assets/icons/icon-pulse.svg"
import { ReactComponent as RoadSignIcon } from "../../assets/icons/icon-roadSign.svg"
import { ReactComponent as SleepIcon } from "../../assets/icons/icon-sleep.svg"
import { ReactComponent as SneakerIcon } from "../../assets/icons/icon-sneaker.svg"
import { ReactComponent as TargetIcon } from "../../assets/icons/icon-target.svg"
import { Card } from "../card/Card"
import styles from "./IconCard.module.css"

export const icons = {
  sleep: SleepIcon,
  meditation: MeditationIcon,
  highFive: HighFiveIcon,
  chest: ChestIcon,
  profileFemale: ProfileFemaleIcon,
  pulse: PulseIcon,
  giveLove: GiveLoveIcon,
  sneaker: SneakerIcon,
  target: TargetIcon,
  roadSign: RoadSignIcon,
  group: GroupIcon,
  device: DeviceIcon,
  gears: GearsIcon,
}

export type IconType = keyof typeof icons

export const IconCard = ({
  icon,
  title,
  body,
}: {
  icon: IconType
  title: string
  body: string
}) => {
  const Icon = icons[icon]
  return (
    <Card className={styles.iconCard}>
      <Icon
        width={64}
        height={64}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          color: "#2D232E",
          opacity: "65%",
        }}
      />
      <h2 className={styles.title}>{title}</h2>
      {body}
    </Card>
  )
}
