import { IconCard, IconType } from "../IconCard/IconCard"

type Themes = {
  [theme: string]: {
    icon: IconType
    body: string
  }
}

const themes: Themes = {
  sleep: {
    icon: "sleep",
    body: "Create restful sleep habits so you aren’t up at night worrying about pain.",
  },
  move: {
    icon: "sneaker",
    body: "Gradually expand your daily activities to feel good at the end of the day.",
  },
  connect: {
    icon: "giveLove",
    body: "Foster supportive emotional connections with friends and family.",
  },
  relax: {
    icon: "meditation",
    body: "Soothe your nervous system with guided meditations that create calm and peace.",
  },
  adapt: {
    icon: "roadSign",
    body: "Develop new methods to manage your emotions and patterns for thinking, feeling and moving.",
  },
  learn: {
    icon: "target",
    body: "Learn about the strategies for pain relief to be better informed and make choices which fit you.",
  },
}

export type ThemeType = keyof typeof themes

export const ThemeCard = ({ theme: themeType }: { theme: ThemeType }) => {
  const theme = themes[themeType]
  if (!theme) {
    return null
  }

  const title = themeType.toString().charAt(0).toUpperCase() + themeType.toString().slice(1)
  return <IconCard icon={theme.icon} title={title} body={theme.body} />
}
