import { AivoTrait } from "../../data/profile"
import { Card } from "../card/Card"
import { Divider } from "../Divider/Divider"
import styles from "./TraitCard.module.css"

export const TraitCard = ({ trait }: { trait: AivoTrait }) => {
  const Image = trait.image
  return (
    <Card style={{ flexBasis: "50%", minWidth: 320 }}>
      <h2 className={styles.title}>{trait.title}</h2>
      <Image className={styles.image} />
      <Divider />
      <h3 className={styles.title}>KEY INSIGHTS</h3>
      {trait.insights.map((insight, index) => (
        <p key={`${trait.title}${index}`} className={styles.listItem}>
          {insight}
        </p>
      ))}
    </Card>
  )
}
