import styles from "./Survey.module.css"
import { Widget } from "@typeform/embed-react"
import { useNavigate } from "react-router-dom"
import { useRef } from "react"

export const buildTrackingUrl = (path: string, params?: Record<string, string>) => {
  const url = new URL(path, "https://aivohealth.com")
  if (params) {
    for (let [param, value] of Object.entries(params)) {
      url.searchParams.set(param, value)
    }
  }
  return url
}

export const goToAppPage = (path: string, params?: Record<string, string>) => {
  const url = buildTrackingUrl("", params)
  return `${path}?${url.searchParams.toString()}`
}

function Survey({
  id = "DQLxeSnO",
  tracking,
  hidden,
}: {
  id?: string
  tracking?: Record<string, string>
  hidden?: Record<string, string>
}) {
  const navigate = useNavigate()
  const responseIdRef = useRef<string | undefined>()

  return (
    <div className={styles.content}>
      <Widget
        id={id}
        className={styles.form}
        tracking={tracking}
        hidden={hidden}
        redirectTarget={"_self"}
        onSubmit={({ responseId }: { responseId: string }) => {
          console.log("onSubmit", responseId)
          responseIdRef.current = responseId
        }}
        onClose={() => console.log("onClose")}
        onEndingButtonClick={() => {
          console.log("onEndingButtonClick")
          navigate(goToAppPage(`/${responseIdRef.current}`, tracking))
        }}
        enableFullscreen={true}
      />
    </div>
  )
}

export default Survey
