import React, { SVGProps } from "react"
import { ReactComponent as GraphBellHigh1 } from "../assets/profile/graph-bell-high1.svg"
import { ReactComponent as GraphBellHigh2 } from "../assets/profile/graph-bell-high2.svg"
import { ReactComponent as GraphBellHigh3 } from "../assets/profile/graph-bell-high3.svg"
import { ReactComponent as GraphBellLow1 } from "../assets/profile/graph-bell-low1.svg"
import { ReactComponent as GraphBellLow2 } from "../assets/profile/graph-bell-low2.svg"
import { ReactComponent as GraphBellLow3 } from "../assets/profile/graph-bell-low3.svg"
import { ReactComponent as GraphBellMed1 } from "../assets/profile/graph-bell-med1.svg"
import { ReactComponent as GraphBellMed2 } from "../assets/profile/graph-bell-med2.svg"
import { ReactComponent as GraphBellMed3 } from "../assets/profile/graph-bell-med3.svg"
import { ChartData } from "../components/Chart/Chart"
import { ThemeType } from "../components/ThemeCard/ThemeCard"
import { painForecastsByStartValue } from "./painForecastValues"

const AivoRecoveryProfileTraitFactors = [
  "optimism",
  "pain_perspective",
  "emotion_aware",
  "flexible_attention",
  "body_aware",
  "adaptable_coping",
] as const
export type AivoRecoveryProfileTraitFactor = typeof AivoRecoveryProfileTraitFactors[number]

const AivoRecoveryProfileRatingFactors = ["pain", "sleep", "mood"] as const
type AivoRecoveryProfileRatingFactor = typeof AivoRecoveryProfileRatingFactors[number]
export type Ratings = {
  [type in AivoRecoveryProfileRatingFactor]?: number
}

const AivoRecoveryProfileAdditionalFactors = [
  "nonjudge_aware",
  "plasticity",
  "emotion_express",
  "pain_quality",
  "emotion_sensitivity",
  "flexible_coping",
  "optimism_neg",
  "pain_anxiety",
] as const
export type AivoRecoveryProfileAdditionalFactor =
  typeof AivoRecoveryProfileAdditionalFactors[number]

const AivoRecoveryProfileLevels = ["high", "low", "medium"] as const
export type AivoRecoverProfileLevel = typeof AivoRecoveryProfileLevels[number]

export type AivoTraitInsights = {
  [factor in AivoRecoveryProfileTraitFactor]: {
    title: string
    insights: {
      [level in AivoRecoverProfileLevel]: string[]
    }
  }
}

const traitInsights: AivoTraitInsights = {
  optimism: {
    title: "Optimism",
    insights: {
      low: [
        "Optimism is an inner knowing that you are on the best path forward for you. Stay open to possibilities for pain relief and avoid getting stuck on any one approach.",
        "Take an adventurous attitude and try new strategies in the Aivo app to find ways you can better manage your pain.",
        "When you feel confused about how to manage your pain, look for activities that give you joy. Seeking out positive emotions is a failsafe strategy for relief.",
      ],
      medium: [
        "Optimism is an inner knowing that you are on the best path forward for you. You are naturally open-minded about new avenues for pain relief. Your flexible and adaptive mindset will help you get the most out of your Aivo program.",
        "Your adventurous attitude toward pain self-management is proof that your courage is stronger than your self-doubt.",
        "When you feel confused about how to manage pain, look for activities that give you joy. Seeking out positive emotions is a failsafe strategy for relief.",
      ],
      high: [
        "Optimism is an inner knowing that you are on the best path forward for you. You are naturally open-minded about new avenues for pain relief. Your flexible and adaptive mindset will help you get the most out of your Aivo program.",
        "Your adventurous attitude toward pain self-management is proof that your courage is stronger than your self-doubt.",
        "When you feel confused about how to manage pain, look for activities that give you joy. Seeking out positive emotions is a failsafe strategy for relief.",
      ],
    },
  },
  pain_perspective: {
    title: "Pain Perspective",
    insights: {
      low: [
        "Your pain perspective reflects how easily you adapt to pain. Shifting your perspective helps you overcome uncertainty related to pain.",
        "The best path forward is experimentation. The Aivo app guides you through new perspectives about pain and new coping strategies. The result? We find the right combination of strategies to reduce your pain.",
        "By grounding yourself in reliable coping strategies that work, you have room to explore the edges of your comfort zone.",
      ],
      medium: [
        "Your pain perspective reflects how easily you adapt to pain. Shifting your perspective helps you overcome uncertainty related to pain.",
        "The best path forward is experimentation. The Aivo app guides you through new perspectives about pain and new coping strategies. The result? We find the right combination of strategies to reduce your pain.",
        "By grounding yourself in reliable coping strategies that work, you have room to explore the edges of your comfort zone.",
      ],
      high: [
        "Your pain perspective reflects how easily you adapt to pain. Shifting your perspective helps you overcome uncertainty related to pain.",
        "It’s time to take your focus to the next level. Savor how good it feels to take healthy risks and explore the edges of your comfort zone. When you feel relief--even for a few hours or days--celebrate it.",
        "Embrace the new! By staying open to new perspectives about pain and new coping strategies, you will feel invigorated about your recovery.",
      ],
    },
  },
  emotion_aware: {
    title: "Emotional Awareness",
    insights: {
      low: [
        "Pain always has a sensory AND emotional basis. Emotional awareness helps you understand and work with emotions related to pain.",
        "Emotional awareness tunes you into what you need to learn about pain. Give yourself permission to feel emotions as you take slow, deep breaths. As you breathe, feel compassion for yourself.",
        "Your natural resiilence wears down when you struggle with emotions. Real resilience is the knowledge that you are more than your pain.",
      ],
      medium: [
        "Pain always has a sensory AND emotional basis. Emotional awareness helps you understand and work with emotions related to pain.",
        "Emotional awareness tunes you into what you need to learn about pain. Give yourself permission to feel emotions as you take slow, deep breaths. As you breathe, feel compassion for yourself.",
        "Your natural resiilence wears down when you struggle with emotions. Real resilience is the knowledge that you are more than your pain.",
      ],
      high: [
        "You excel at honoring your inner authority. You refuse to give control of your pain relief to the external world (medication, doctors, the healthcare system), which enhances your confidence in your ability to control pain.",
        "You are great at honoring your inner authority. You refuse to give control to the external world (medication, doctors, the healthcare system). Deep down, you know your relief is in your own hands.",
        "Self-doubt can wear down your resilience. Real resilience is the knowledge that you are more than your pain.",
      ],
    },
  },
  flexible_attention: {
    title: "Flexible Attention",
    insights: {
      low: [
        "Your goal is to create new habits that lead to pain relief. Old habits suck you into autopilot mode. Running on autopilot is the opposite of flexible attention.",
        "What is the antidote to autopilot? Creating  new habits and reducing distractions. By setting realistic goals with your Aivo health coach, you can re-direct your attention when distractions arise.",
        "Consistency is your best friend. Even if you know the “right” ways to cope, inconsistent coping efforts leave you feeling unmotivated.",
      ],
      medium: [
        "Your goal is to create new habits that lead to pain relief. Old habits suck you into autopilot mode. Running on autopilot is the opposite of flexible attention.",
        "What is the antidote to autopilot? Creating  new habits and reducing distractions. By setting realistic goals with your Aivo health coach, you can re-direct your attention when distractions arise.",
        "Consistency is your best friend. Even if you know the “right” ways to cope, inconsistent coping efforts leave you feeling unmotivated.",
      ],
      high: [
        "Attention shapes your reality. The key to your pain relief is working towards meaningful goals that improve your quality of life.",
        "Consistency is your best friend. You intuitively know the “right” ways to cope for different situations. Your Aivo health coach will help you stay motivated and inspired.",
        "When distractions pull your focus away from pain relief, you may feel fear or anxiety. Think of negative emotions as internal cues that it’s time to re-focus on your pain relief goals.",
      ],
    },
  },
  body_aware: {
    title: "Body Awareness",
    insights: {
      low: [
        "Pain makes your body tense up and move differently. The way your body reacts to pain can make flare-ups more stressful. Your goal is to bring your body back to baseline.",
        "Embrace mindful rest and stillness. Focus on movement and activities that respect your body’s current capabilities. Avoid pushing limits when you body needs rest.",
        "Deep breathing is key to recovery. When you feel safe in your body, pain no longer feels threatening.",
      ],
      medium: [
        "Pain makes your body tense up and move differently. The way your body reacts to pain can make flare-ups more stressful. Your goal is to bring your body back to baseline.",
        "Embrace mindful rest and stillness. Focus on movement and activities that respect your body’s current capabilities. Avoid pushing limits when you body needs rest.",
        "Deep breathing is key to recovery. When you feel safe in your body, pain no longer feels threatening.",
      ],
      high: [
        "Pain makes your body tense up and move differently. Your superpower is your ability to bring your body back to baseline.",
        "When you are “in the moment,” there is no room to judge the body that has carried you this far. Neutral detachment is your most powerful tool.",
        "Expand on new ways to embrace mindful rest and stillness. The more you return to a baseline of relaxed awareness, the more resilient you are to everyday stress.",
      ],
    },
  },
  adaptable_coping: {
    title: "Adaptable Coping",
    insights: {
      low: [
        "Courageously feeling your emotions is the key to adaptable coping. By accepting yourself in the present moment, with all the pain and messy feelings, you can move forward.",
        "To expand your coping toolbox, your program will combine pain neuroscience education, advanced coping strategies,  gentle movement, guided meditations, relaxation, sleep hygiene, social connection, and empathic health coaching.",
        "Your roadmap will focus on exploring new coping strategies to maximize pain relief.",
      ],
      medium: [
        "Courageously feeling your emotions is the key to adaptable coping. By accepting yourself in the present moment, with all the pain and messy feelings, you can move forward.",
        "To expand your coping toolbox, your program will combine pain neuroscience education, advanced coping strategies,  gentle movement, guided meditations, relaxation, sleep hygiene, social connection, and empathic health coaching.",
        "Your roadmap will focus on exploring new coping strategies to maximize pain relief.",
      ],
      high: [
        "Courageously feeling your emotions is the key to adaptable coping. By accepting yourself in the present moment, with all the pain and messy feelings, you can move forward.",
        "To expand your coping toolbox, your program will combine pain neuroscience education, advanced coping strategies,  gentle movement, guided meditations, relaxation, sleep hygiene, social connection, and empathic health coaching.",
        "Your roadmap will focus on combining successful coping strategies to maximize pain relief.",
      ],
    },
  },
}

export type AivoTrait = {
  title: string
  image: React.FunctionComponent<SVGProps<SVGSVGElement>> & { title?: string }
  insights: string[]
}

export type AggregateScore = {
  name:
    | AivoRecoveryProfileTraitFactor
    | AivoRecoveryProfileRatingFactor
    | AivoRecoveryProfileAdditionalFactor
  score: number
  level: AivoRecoverProfileLevel
}

export type PainForecast = ChartData[]

export type AivoRecoveryProfile = {
  aggregateScores: AggregateScore[]
  biotype: string
  recommendations: ThemeType[]
  email: string
}

export type WebProfile = {
  ratings: Ratings
  traits: AivoTrait[]
  themes: ThemeType[]
  painForecast?: PainForecast
  email: string
}

export class AivoRecoveryProfileRetrievalError extends Error {}

const getAivoRecoveryProfile = async (customerId: string): Promise<AivoRecoveryProfile> => {
  for (let i = 0; i < 10; i++) {
    const result = await fetch(
      `https://valohai.cloud/aivohealth/profile-server/recovery-profile-server-1/production/profile-server/biotype/${customerId}`,
    )
    if (result.ok) {
      return result.json()
    } else {
      // Sleep for a moment before retry.
      await new Promise((resolve) => setTimeout(resolve, 2000))
    }
  }
  throw new AivoRecoveryProfileRetrievalError("Failed to get recovery profile")
}

/**
 *
 * bell curve slice thresholds,
 * see https://smtgcorp.slack.com/archives/CGKQMS7AM/p1662455290236299?thread_ts=1662377465.830529&cid=CGKQMS7AM:
 * adjusted by replacing +/-0.4307.. with our in-house thresholds +/-0.425
 *
 * [-1.22064034884735,
 * -0.7647096737863871,
 * -0.425,
 * -0.13971029888186212,
 * 0.13971029888186212,
 * 0.425,
 * 0.7647096737863871,
 * 1.2206403488473496]
 */

const getTraitImage = (aggregateScore: AggregateScore) => {
  console.log(aggregateScore)
  if (aggregateScore.level === "low") {
    if (aggregateScore.score < -1.22064034884735) {
      return GraphBellLow1
    }
    if (aggregateScore.score < -0.7647096737863871) {
      return GraphBellLow2
    }
    return GraphBellLow3
  } else if (aggregateScore.level === "medium") {
    if (aggregateScore.score < -0.13971029888186212) {
      return GraphBellMed1
    }
    if (aggregateScore.score < 0.13971029888186212) {
      return GraphBellMed2
    }
    return GraphBellMed3
  } else {
    if (aggregateScore.score < 0.7647096737863871) {
      return GraphBellHigh1
    }
    if (aggregateScore.score < 1.2206403488473496) {
      return GraphBellHigh2
    }
    return GraphBellHigh3
  }
}

const getRatings = (recoveryProfile: AivoRecoveryProfile) => {
  const ratingTypesSet = new Set<string>(AivoRecoveryProfileRatingFactors)
  return recoveryProfile.aggregateScores
    .filter((score) => ratingTypesSet.has(score.name))
    .reduce((ratings, { name, score }) => {
      // @ts-ignore
      ratings[name] = score
      return ratings
    }, {} as Ratings)
}

export const getWebProfile = async (customerId: string): Promise<WebProfile> => {
  const recoveryProfile = await getAivoRecoveryProfile(customerId)
  const ratings = getRatings(recoveryProfile)
  const startingPainLevel = ratings.pain ?? NaN
  const painForecast = !isNaN(startingPainLevel)
    ? painForecastsByStartValue[startingPainLevel]
    : undefined
  return AivoRecoveryProfileTraitFactors.reduce(
    (profile, factor: AivoRecoveryProfileTraitFactor) => {
      const trait = traitInsights[factor]
      const aggregateScore = recoveryProfile.aggregateScores.find(({ name }) => name === factor)
      if (trait && aggregateScore && trait.insights[aggregateScore.level]) {
        const image = getTraitImage(aggregateScore)
        profile.traits.push({
          title: trait.title,
          image,
          insights: trait.insights[aggregateScore.level],
        })
      }
      return profile
    },
    {
      ratings,
      traits: [] as AivoTrait[],
      themes: recoveryProfile.recommendations ?? [],
      painForecast,
      email: recoveryProfile.email,
    } as WebProfile,
  )
}
