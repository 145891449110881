import React from "react"
import styles from "./Card.module.css"

export const Card = ({
  children,
  style,
  className,
}: {
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
}) => {
  return (
    <div className={className ?? styles.card} style={style}>
      <div className={styles.cardContent}>{children}</div>
    </div>
  )
}
