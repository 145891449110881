import React from "react"
import { ReactComponent as Logo } from "../../assets/logo.svg"
import { ReactComponent as Swirl } from "../../assets/swirl.svg"
import styles from "./PageLayout.module.css"

const FooterLink = ({ link, children }: { link: string; children: React.ReactNode }) => (
  <a href={link} target="_blank" rel="noreferrer" className={styles.link}>
    {children}
  </a>
)
const PageLayout = ({
  footerLogo = true,
  children,
}: {
  footerLogo?: boolean
  children: React.ReactNode
}) => {
  return (
    <div className={styles.appContainer}>
      <div className={styles.app}>
        <div className={styles.appContent}>
          <div className={styles.appContentInner}>
            <header className={styles.header}>
              <Swirl className={styles.swirl} />
              <Logo className={styles.logo} />
            </header>
            {children}
          </div>
          <footer>
            {footerLogo && (
              <>
                <Logo className={styles.footerLogo} />
                <p>www.aivohealth.com</p>
                <p>© Aivo Health, Inc. 2022</p>
              </>
            )}
            <div className={`${styles.linkBox}`}>
              <FooterLink
                link="javascript:Cookiebot.renew()" // eslint-disable-line
              >
                Cookie settings
              </FooterLink>
              <FooterLink link="https://aivohealth.com/terms-of-use">Terms of use</FooterLink>
              <FooterLink link="https://aivohealth.com/privacy-policy">Privacy policy</FooterLink>
              <FooterLink link="https://aivohealth.com/compliance">Compliance</FooterLink>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}
export default PageLayout
