import { ChartData } from "../components/Chart/Chart"

type AivoPainForecastMap = Record<number, ChartData[]>

const createForecast = (startValue: number): ChartData[] => {
  const labels = ["30 days", "60 days", "90 days", "120 days"]
  const reductions = [0.82, 0.76, 0.68, 0.54]
  const firstError = 1.4
  const lastError = startValue * reductions[reductions.length - 1] - startValue / 10

  const N = labels.length
  const data = labels.map((name, i) => {
    const value = startValue * reductions[i]
    const errorAmount = Math.min(
      10 - value,
      startValue - value + startValue / 10,
      value - startValue / 10,
      (firstError * (N - 1 - i)) / (N - 1) + (lastError * i) / (N - 1),
    )
    return {
      name,
      value,
      errorAmount,
    }
  })

  return [
    { name: "Today", value: startValue, errorAmount: Math.min(10 - startValue, startValue, 0.2) },
  ].concat(data)
}

export const painForecastsByStartValue: AivoPainForecastMap = Object.fromEntries(
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => [i, createForecast(i)]),
)
