import { useState } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom"
import Home from "./components/Home/Home"
import PageLayout from "./components/PageLayout/PageLayout"
import Survey from "./components/Survey/Survey"
import { ProfilePage } from "./page/ProfilePage/ProfilePage"

function MyRoutes() {
  const [searchParams] = useSearchParams()
  const { formId, ...initialParams } = Object.fromEntries(searchParams.entries())
  const [params, setParams] = useState(initialParams)

  const location = useLocation()
  const parsedHash = new URLSearchParams(location.hash.substring(1))
  const hash = Object.fromEntries(parsedHash.entries())

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageLayout footerLogo={false}>
            <Survey id={formId} tracking={params} hidden={hash} />
          </PageLayout>
        }
      />
      <Route
        path="/home"
        element={
          <PageLayout>
            <Home />
          </PageLayout>
        }
      />
      <Route
        path="/:id"
        element={<ProfilePage joinButtonParams={params} setParams={setParams} />}
      />
    </Routes>
  )
}

function App() {
  return (
    <Router>
      <MyRoutes />
    </Router>
  )
}

export default App
