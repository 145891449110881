import { Link } from "react-router-dom"
import styles from "./Home.module.css"

function Home() {
  return (
    <div className={styles.content}>
      <ul>
        <li>
          {"An example profile can be found "}
          <Link to="/ilvjljvq0bf2pg2jsyil6laeso0ax5v9">here</Link>
        </li>
        <li>
          {"A short survey test link can be found "}
          <Link to="/?formId=KfLpHqjt&promo_code=PROFILE&utm_campaign=Pain+recovery+assessment+and+profile&utm_content=Link+to+the+recovery+profile&utm_medium=email_action&utm_source=customer.io">
            here
          </Link>
        </li>
        <li>
          {" "}
          {"The full survey can be found "}
          <Link to="/?promo_code=PROFILE&utm_campaign=Pain+recovery+assessment+and+profile&utm_content=Link+to+the+recovery+profile&utm_medium=email_action&utm_source=customer.io">
            here
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Home
